<template>
  <div class="explanation">
    <h2 class="explanationHedaer">Mix and match your room</h2>
    Simply 'click and drag' your selections into My Selections and see which combo you like best. And remember, no pressure at all, you can purchase as few or as many pieces as you like!<br/><br/>
    <span style="font-weight: 500;">(For the best Mix & Match experience we highly recommend using a desktop computer.)</span>
  </div>
  <div class="room-builder" ref="roomBuilder">
    <!-- HEADERS -->
    <div class="grid-container header">
      <div class="grid-item header-1">
        <!-- <div class="sharingBanner">
            <span>Need help deciding? Share & get opinions!</span>
          <button
                class="btn-Border sharingButton"
                @click="!shareLink ? generateShareLink() : null; showShareModal = true"
                v-if="selection"
              >
                Share
          </button>
        </div> -->
        <p>
          <!-- <span
            v-if="associatedSurvey.projectAddress && associatedSurvey.projectAddress.zip"
            class="boards__item__address"
          >
            {{ associatedSurvey.projectAddress.zip }} |
          </span> -->
          <span v-if="associatedSurvey.responses && associatedSurvey.responses.type_of_furniture">
            {{ associatedSurvey.responses.type_of_furniture || 'My Room' }}
          </span>
        </p>
        <h1>{{ associatedSurvey.projectName }}</h1>
      </div>
      <div class="grid-item header-2 selection-header">
        <h1>
          My Selections
          <button class="info" @click="explainerModalShown = true">
            Info
          </button>
        </h1>
      </div>
    </div>
    <Modal
      v-if="showShareModal"
      @close="showShareModal = false"
      @confirm="showShareModal = false"
      :fromTimeMixPopup="true"
    >
      <template v-slot:header>
        <button
          class="modal__close time_mix_popup_close"
          type="button"
          aria-label="Close modal"
          @click="showShareModal = false"
        >
          Close
        </button>
        Share Your Selection
      </template>
      <template v-slot:body>
        <div class="share-modal-content">
          <div v-if="shareLink" class="share-link-container">
            <input
              type="text"
              :value="shareLink"
              readonly
              ref="linkInput"
            >
            <button @click="copyShareLink" class="btn-secondary">
              {{ copied ? 'Copied!' : 'Copy Link' }}
            </button>
          </div>
          <button
            v-if="!shareLink"
            @click="generateShareLink"
            class="btn-primary"
            :disabled="generatingLink"
          >
          <div v-if="generatingLink" class="loading">
              <Loader :visible="true" />
          </div>
          </button>
        </div>
      </template>
    </Modal>
    <!-- GALLERY -->
    <div class="grid-container content-container">

      <div class="grid-item gallery-column">
        <div class="grid-container">
          <!-- BOARD A -->
          <div class="grid-item column-1">
            <div class="descriptionWrapper"><h4 class="column-title">FURNITURE BOARD A</h4><div class="boardDescription" v-if="selection != null" >{{ selection.boards[0].description }}</div></div>
            <RoomBuilderThumbnails
              v-if="selection != null"
              class="thumbnails-1"
              :products="selection.boards[0].furniture"
              :isMobile="isMobile"
            />
          </div>

          <div class="column-divider"></div>

          <!-- BOARD B -->
          <div class="grid-item column-2">
            <div class="descriptionWrapper"><h4 class="column-title">FURNITURE BOARD B</h4><div class="boardDescription" v-if="selection != null" >{{ selection.boards[1].description }}</div></div>
            <RoomBuilderThumbnails
              v-if="selection != null"
              class="thumbnails-2"
              :products="selection.boards[1].furniture"
              :isMobile="isMobile"
            />
          </div>
        </div>
        <div class="feedback-container">
          <p class="feedback-container__button" @click="feedbackModal.shown = true">
            Wanting some alternates?
          </p>
        </div>
      </div>

      <!-- STAGE -->
      <div class="grid-item stage-column">
        <!-- Passing in offsetY as a prop so we don't have to send it in payload below -->
        <RoomBuilderStage
          ref="stage"
          class="stage"
          :offsetY="offsetY"
          @ITEM_INFO="handleItemInfo"
        />
      </div>
    </div>

    <!-- FURNITURE DETAIL -->
    <transition name="fade">
      <FurnitureDetail
        v-if="productInfoObject"
        class="furniture-detail"
        :product="productInfoObject"
        @FURNITURE_DETAIL_ITEM_REMOVE="handleFurnitureDetailItemRemove"
        @FURNITURE_DETAIL_CLOSE="productInfoObject = null"
        @AUTO_ADD_ITEM="addItemAutomated"
      />
    </transition>

    <!-- Feedback modal -->
    <transition name="fade">
      <Modal
        v-if="feedbackModal.shown"
        confirmLabel="Send"
        @close="closeFeedbackModal"
        @confirm="sendFeedback"
        centered
        :fromTimeMixPopup=true
      >
        <template v-slot:header>
          <button
            class="modal__close time_mix_popup_close"
            type="button"
            aria-label="Close modal"
            @click="closeFeedbackModal"
          >
            Close
          </button>
          Feedback
        </template>
        <template v-slot:body>
          <TextAreaTt
            v-model="feedbackModal.userFeedback"
            :question="{ question: 'Let us know what you like and don\'t like about these options, and we\'ll let you know when we have a new selection for review.' }"
          />
          <Loader :visible="feedbackModalWaiting" blocking />
        </template>
      </Modal>
    </transition>
    <transition name="fade">
      <Modal
        v-if="explainerModalShown"
        :blurredBackground="!alreadyPaid"
        confirmLabel="Unlock your furniture"
        confirmedButtonClass="gold"
        centered
        :hideActions="alreadyPaid"
        :maxBodyWidth="400"
        @confirm="closeExplainerModal"
        @close="closeExplainerModal"
        :fromTimeMixPopup=true
      >
        <template v-slot:header>
          <button
            class="modal__close time_mix_popup_close"
            type="button"
            aria-label="Close modal"
            @click="close()"
          >
            Close
          </button>
          Time to Mix &amp;<br>Match Your Room
        </template>
        <template v-slot:body>
          <span class="above-tablet-only">
            Now that we've curated your boards, time for some fun. Simply 'click and drag your' selections into My Selections and see which combo you like the best. And remember, no pressure at all. Purchase as few or as many pieces as you like!
          </span>
          <span class="tablet-only">
            Now that we've nailed your Style Boards, it's your turn!
          </span>
          <br>
          <!-- <span class="tablet-only">To order furniture, simply tap the item you're interested in and add it to your cart.<br><br></span><span class="above-tablet-only">Simply ‘click and drag’ furniture into My Selections and see which combo you like best. (And don’t worry, we’ve made sure all your furniture options work together!)</span> -->
          <span class="tablet-only">For the full Mix &amp; Match experience, please view your selections in the toTree desktop version.</span>
        </template>
      </Modal>
    </transition>
    <transition name="fade">
      <Modal
        v-if="paymentModalShown && !alreadyPaid"
        :blurredBackground="!alreadyPaid"
        confirmLabel="Processing....."
        confirmedButtonClass="gold"
        centered
        @confirm="closePaymentModal"
        :fromTimeMixPopup=true
      >
        <template v-slot:header>
          Payment Information
        </template>
        <template v-slot:body>
          <span>
            You're one step away from your hand-selected furniture! And remember that our  $149 Customization Fee is good for up to 6 months!
          </span>
          <Payment ref="paymentStep" required />
          <Loader :visible="loading || clipboardLoader" blocking />
        </template>
      </Modal>
    </transition>

    <div class="divider"></div>
    <div class="draggable-item"></div>
    <div class="outline"></div>
  </div>
</template>

<script>
import { randomIntFromInterval } from '@/assets/scripts/helpers'
import { gsap } from 'gsap'
import RoomBuilderThumbnails from '@/components/room-builder/room-builder-thumbnails.vue'
import RoomBuilderStage from '@/components/room-builder/room-builder-stage.vue'
import FurnitureDetail from '@/components/room-builder/furniture-detail.vue'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import Payment from '@/components/results/Payment'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { httpsCallable } from '@firebase/functions'

const stageWidth = 660
const stageHeight = 760
const stageTop = 216
const stageLeft = 800

export default {
  components: {
    RoomBuilderThumbnails,
    RoomBuilderStage,
    FurnitureDetail,
    Modal,
    Loader,
    Payment
  },

  data () {
    return {
      loading: false,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      headerOffset: 100,
      offsetY: 0,
      selection: null,
      targetFurnitureId: null,
      draggableItem: null,
      productInfoObject: null,
      associatedSurvey: {},
      selectedItems: [],
      isMobile: false,
      feedbackModal: {
        shown: false,
        userFeedback: null
      },
      feedbackModalWaiting: false,
      explainerModalShown: false,
      paymentModalShown: false,

      dragTimeout: null,
      dragging: false,

      showShareModal: false,
      shareLink: null,
      generatingLink: false,
      copied: false
    }
  },

  computed: {
    ...mapState(['firestore', 'checkoutSelectionId', 'fbFunctions', 'clipboardLoader']),
    ...mapGetters(['currentUser', 'userProfile']),
    alreadyPaid () {
      return this.userProfile.selectionFeeOrderStatus === 'complete' || this.associatedSurvey.isPreview
    }
  },

  watch: {
    isMobile: {
      deep: true,
      handler: function (val) {
        // RELOAD PAGE WHEN IN DESKTOP VIEW.
        // HACK TO GET PRODUCTS TO SHOW IF COMING FROM MOBILE
        if (val === false) {
          this.$router.go()
        }
      }
    }
  },

  async mounted () {
    this.associatedSurvey = await this.getTargetSurvey({ id: this.$route.params.selectionId })
    if (this.userProfile.selectionFeePopUp) {
      this.setGlobalNotification('Payment Successful')
      this.setPageLoading(true)
      const paymentStatusData = { selectionFeePopUp: false }
      await this.updateFirestoreUserProfile(paymentStatusData)
    } else {
      this.setPageLoading(true)
      // if (!localStorage.getItem('explainerModalViewed')) {
      // }
    }

    this.selection = await this.getDetailedSelection(this.$route.params.selectionId)

    //

    this.$nextTick(() => {
      const roomBuilder = this.$refs.roomBuilder
      window.addEventListener('resize', this.handleWindowResize)
      this.offsetY = this.getBounds(roomBuilder).y + this.headerOffset

      const thumbnails1 = roomBuilder.querySelector('.thumbnails-1')
      const thumbnails2 = roomBuilder.querySelector('.thumbnails-2')

      if (thumbnails1) thumbnails1.onmousedown = this.handleMouseDown
      if (thumbnails2) thumbnails2.onmousedown = this.handleMouseDown

      this.draggableItem = roomBuilder.querySelector('.draggable-item')
      this.handleWindowResize(null)

      if (this.selection?.chosen?.length) {
        //
        this.selection.chosen.forEach((item) => {
          this.addItemAutomated(item)
        })
      }
    })

    this.emitter.on('THUMBNAIL_SELECT', this.handleThumbnailSelect)
    this.emitter.on('THUMBNAIL_DESELECT', this.handleThumbnailDeselect)
    this.emitter.on('ITEM_REMOVED', this.handleStageItemRemove)
    this.emitter.on('ITEM_INFO', this.handleItemInfo)
    this.emitter.on('FURNITURE_DETAIL_CLOSE', () => {
      this.productInfoObject = null
    })
    this.associatedSurvey = await this.getTargetSurvey({ id: this.$route.params.selectionId })

    this.setPageLoading(false)
  },

  // Called before leaving the route
  unmounted () {
    // We need to remove these listeners, otherwise they'll be doubled up
    // when returning to the route, and old handlers will reference elements
    // which no longer exist.
    //
    window.removeEventListener('resize', this.handleWindowResize)
    this.emitter.off('THUMBNAIL_SELECT', this.handleThumbnailSelect)
    this.emitter.off('THUMBNAIL_DESELECT', this.handleThumbnailDeselect)
    this.emitter.off('ITEM_REMOVED', this.handleStageItemRemove)
    this.emitter.off('ITEM_INFO', this.handleItemInfo)

    this.resetSelectedItems()
  },

  methods: {
    ...mapMutations([
      'setPageLoading',
      'setGlobalNotification',
      'setGlobalError',
      'resetSelectedItems'
    ]),
    ...mapActions(['getDetailedSelection', 'updateTargetSelection', 'getTargetSurvey', 'getSelections', 'updateFirestoreUserProfile']),

    getBounds (el) {
      return el.getBoundingClientRect()
    },
    close () {
      // this.$emit('closeModalSignupEvent')
      localStorage.setItem('explainerModalViewed', true)
      this.explainerModalShown = false
      if (!this.alreadyPaid) {
        this.paymentModalShown = true
      }
    },
    getDataElementByID (id) {
      var result1 = this.selection.boards[0].furniture.find(item => item.recordId === id)
      var result2 = this.selection.boards[1].furniture.find(item => item.recordId === id)
      return (result1 !== undefined) ? result1 : result2
    },

    /**
     * Adds the item the user is currently dragging over the "My Selections" area
     */
    addCurrentItem () {
      const obj = this.getDataElementByID(this.draggableItem.lastChild.parentNode.dataset.id)
      const element = this.draggableItem.lastChild
      const x = element.getBoundingClientRect().left
      const roomBuilder = this.$refs.roomBuilder
      const stageBounds = roomBuilder.querySelector('.stage').getBoundingClientRect()

      this.$refs.stage.addItem({
        id: obj.recordId,
        name: obj.name,
        price: obj.itemPrice,
        size: obj.size,
        image: element.src,
        x: element.getBoundingClientRect().left,
        y: element.getBoundingClientRect().top,
        percentX: (x - stageBounds.left) / stageBounds.width
      })
      this.getSelections({ furnitureId: obj.recordId })
    },

    /**
     * Adds an item to the selections automatically, without user interaction.
     */
    addItemAutomated (item) {
      // Get the target thumbnail to mark it visually selected
      const targetElement = document.querySelector(`.thumbnail[data-id='${item.id}']`)
      const node = targetElement.firstChild
      this.selectItem(node)
      const clone = node.cloneNode(true)
      this.selectedItems.push({
        id: targetElement.dataset.id,
        node,
        clone
      })

      //
      const obj = this.getDataElementByID(item.id)

      this.$refs.stage.addItem({
        id: item.id,
        name: obj.name,
        price: obj.itemPrice,
        size: obj.size,
        image: obj.cloudinaryImages[0].url,
        x: item.x || randomIntFromInterval(stageLeft, stageLeft + stageWidth - 200),
        y: item.y || randomIntFromInterval(stageTop, stageTop + stageHeight - 200) - window.scrollY,
        percentX: item.percentX || 0.5
      })
      this.getSelections({ furnitureId: item.id })
    },

    removeCurrentItem () {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.draggableItem.lastChild === this.selectedItems[i].clone) {
          this.deselectItem(i)
          break
        }
      }
    },

    closeExplainerModal () {
      localStorage.setItem('explainerModalViewed', true)
      this.explainerModalShown = false
      if (!this.alreadyPaid) {
        this.paymentModalShown = true
      }
    },

    async closePaymentModal () {
      try {
        await this.handlePayment()
      } catch (err) {
        this.setGlobalError(err.message)
        // If there's a file uploader on the page, set the error.
        this.loading = false
        return
      }
      this.paymentModalShown = false
    },

    closeFeedbackModal () {
      this.feedbackModal.shown = false
      this.feedbackModal.userFeedback = ''
    },

    handleThumbnailSelect (e) {
      const obj = this.getDataElementByID(e.id)
      const itemX = randomIntFromInterval(stageLeft + 100, stageLeft + stageWidth - 200)
      const itemY = randomIntFromInterval(stageTop + 100, stageTop + stageHeight - 200)

      this.$refs.stage.addItem({
        id: obj.recordId,
        name: obj.name,
        price: obj.itemPrice,
        size: obj.size,
        image: obj.cloudinaryImages[0].url,
        x: itemX,
        y: itemY,
        percentX: (itemX - stageLeft) / stageWidth
      })
    },

    handleThumbnailDeselect (e) {
      this.productInfoObject = null
      this.$refs.stage.removeItem(e)
    },

    handleWindowResize (e = null) {
      if (window.innerWidth <= 1024) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },

    /**
     * Called when an item is removed from the selected furniture area.
     * Note that it also restores the appearance / behavior of the associated thumbnail.
     */
    handleStageItemRemove (obj) {
      //
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (obj.id === this.selectedItems[i].id) {
          this.deselectItem(i)
          break
        }
      }

      this.productInfoObject = null
    },

    handleFurnitureDetailItemRemove (obj) {
      this.productInfoObject = null
      this.$refs.stage.removeItem(obj)
    },

    handleItemInfo (id) {
      this.targetFurnitureId = id
      this.productInfoObject = this.getDataElementByID(id)
    },

    selectItem (node) {
      node.style.opacity = 0.2
      node.style['pointer-events'] = 'none'
    },

    deselectItem (index) {
      localStorage.removeItem('product_item_' + this.selectedItems[index].id)
      gsap.to(this.selectedItems[index].node, {
        opacity: 1,
        'pointer-events': 'all',
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })
      this.selectedItems.splice(index, 1)
    },

    handleMouseDown (e) {
      e = e || window.event
      e.preventDefault()

      this.dragTimeout = setTimeout(() => {
        this.dragging = true
      }, 300)

      if (!this.isMobile) {
        if (e.target.parentNode.dataset.selected === 'false') {
          if (e.target.parentNode.className === 'thumbnail') {
            if (e.target.className === 'thumb') {
              const node = e.target
              const clone = node.cloneNode(true)

              clone.style.position = 'absolute'
              clone.style.left = '0px'
              clone.style.top = '0px'

              this.draggableItem.dataset.id = e.target.parentNode.dataset.id
              this.draggableItem.appendChild(clone)

              const roomBuilder = this.$refs.roomBuilder

              gsap.to(this.draggableItem, {
                left: this.getBounds(node).x - this.getBounds(roomBuilder).x,
                top: this.getBounds(node).y - this.getBounds(roomBuilder).y,
                display: 'block',
                duration: 0
              })

              this.pos3 = e.clientX
              this.pos4 = e.clientY

              document.onmouseup = this.handleDragRelease
              document.onmousemove = this.handleDrag

              this.selectItem(node)

              this.selectedItems.push({
                id: e.target.parentNode.dataset.id,
                node,
                clone
              })

              gsap.to(this.draggableItem, {
                opacity: 0.8,
                duration: 0.5,
                ease: 'Cubic.easeInOut'
              })
            }
          }
        }
      }
    },

    handleDrag (e) {
      e = e || window.event
      e.preventDefault()

      this.pos1 = this.pos3 - e.clientX
      this.pos2 = this.pos4 - e.clientY
      this.pos3 = e.clientX
      this.pos4 = e.clientY

      this.draggableItem.style.left = (this.draggableItem.offsetLeft - this.pos1) + 'px'
      this.draggableItem.style.top = (this.draggableItem.offsetTop - this.pos2) + 'px'
    },

    handleDragRelease (e) {
      if (!this.dragging) {
        this.handleItemInfo(this.draggableItem.lastChild.parentNode.dataset.id)
      }
      clearTimeout(this.dragTimeout)
      this.dragging = false

      document.onmouseup = null
      document.onmousemove = null

      if (this.$refs.stage.withinBounds(this.draggableItem.lastChild)) {
        this.addCurrentItem()
      } else {
        this.removeCurrentItem()
      }

      while (this.draggableItem.firstChild) {
        this.draggableItem.removeChild(this.draggableItem.firstChild)
      }

      gsap.to(this.draggableItem, {
        display: 'none',
        opacity: 1,
        duration: 0
      })
    },

    async sendFeedback () {
      this.feedbackModalWaiting = true

      try {
        await httpsCallable(this.fbFunctions, 'sendSelectionFeedback')({
          selectionId: this.$route.params.selectionId,
          feedback: this.feedbackModal.userFeedback
        })

        this.setGlobalNotification('Feedback received.')
        this.closeFeedbackModal()
      } catch (err) {
        this.setGlobalError('Sorry, an error occurred when sending your feedback.')
      }
      this.feedbackModalWaiting = false
    },

    /* Payment */
    async handlePayment () {
      this.loading = true

      // Note that we only need this special case if there's not already a completed order for this user.
      if (this.userProfile.selectionFeeOrderStatus !== 'complete') {
        const response = await this.$refs.paymentStep.atStripeSubmitConfirmPayment()

        this.stripePaymentResponse = response

        if (this.stripePaymentResponse) { // checks response from Stripe and provides info for next page.
          // If the total amount is 0, there will be no payment intent
          if (this.stripePaymentResponse.paymentIntent || this.stripePaymentResponse.order?.amount_total === 0) {
            // SAVE ANY RELEVANT ORDER INFO HERE FOR THE NEXT PAGE IN THE QUIZ
            this.loading = false
            this.setGlobalNotification('Payment Successful')
            return false
          } else {
            this.loading = false
            throw new Error()
          }
        }
      }
      this.loading = false
    },
    async generateShareLink () {
      this.generatingLink = true
      try {
        const getToken = httpsCallable(this.fbFunctions, 'getSelectionsShareToken')
        const result = await getToken({
          selectionId: this.$route.params.selectionId
        })

        this.shareLink = `${window.location.origin}/shared-selection/${result.data.token}`
      } catch (error) {
        console.error('Error generating share link:', error)
        this.setGlobalError('Error generating share link')
      } finally {
        this.generatingLink = false
      }
    },

    copyShareLink () {
      const linkInput = this.$refs.linkInput
      linkInput.select()
      document.execCommand('copy')

      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 2000)
    }
    /* methods end */
  }
}
</script>

<style lang="scss">
.room-builder {
  margin-top: 48px;
  margin-bottom: 100px;
  position: relative;
  // height: 875px;
  @include breakpoint($m-down) {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .outline {
    position: absolute;
    border: 15px solid #F9F8F4;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .divider {
    position: absolute;
    width: 10px;
    height: 100%;
    top: 0;
    left: 50%;
    background: #F9F8F4;
  }

  .column-divider {
    position: absolute;
    height: calc(100% - 50px);
    bottom: 0;
    left: 50%;
    border: 1px solid #73716F;
    opacity: 0.25;
  }

  .grid-container {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
  }

  .header {
    height: 100px;
    font-family: 'GT America Expanded';
    font-style: normal;
    font-weight: 300;
    font-size: 38px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #1D1B19;

    .header-1 {
      margin-top: 20px;

      p {
        font-family: 'GT America Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 133%;
        text-align: center;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #576B5C;
        width: 100%;
        height: 20px;
        margin: 0;
      }

      h1 {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .header-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      h1 {
        position: relative;
        button {
          position: absolute;
          top: 0;
          right: -1.66em;
        }
      }
    }
  }

  .content-container {
    margin: 0;
    padding: 0;
    text-align: center;

    .column-title {
      margin-bottom: 20px;
      font-family: 'Sohne' !important;
    }

    .gallery-column {
      position: relative;
      margin: 0;
      padding: 0;

      .feedback-container {
        background-color: $tt-bright-white;
        width: calc(100% - 15px);
        position: absolute;
        bottom: 15px;
        left: 15px;

        @include breakpoint($m-down) {
          bottom: 0;
          left: 0;
          width: 100%;
        }

        &__button {
          cursor: pointer;
          color: $tt-green;
          text-transform: uppercase;
          font-family: 'GT America Expanded';
          padding: 1rem;

          @include breakpoint($s) {
            font-size: 0.75rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .draggable-item {
    position: absolute;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: none;
    border: none;
    top: 0;
    width: 200px;
  }

  .furniture-detail {
    width: 50%;
    margin-left: 5px;
  }

  .gold {
    color: white;
  }

  @include breakpoint($m-down) {
    .room-builder {
      height: auto;
      margin-bottom: 0px;
    }

    .furniture-detail {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }

    .content-container {
      display: block;
    }

    .grid-container {
      padding: 0px;
      height: auto;

      .column-1 {
        padding-right: 15px;
      }

      .column-2 {
        padding-left: 15px;
      }
    }

    .header {
      display: block;
      height: auto;
      padding-bottom: 25px;

      .header-2 {
        display: none;
      }
    }

    .outline, .divider {
      display: none;
    }
  }
}

.header-1, .header-2 {
  font-family: 'Sohne' !important;
}

.sharingBanner{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 100%;
  background-color: #004021;
  color: white;
  font-size: 20px;
  font-weight: 500;
  padding: 16px 30px;
  letter-spacing: 0.5px;
}

.sharingButton {
  background-color: #F6FF93;
  color: #004021;
  min-width: 200px;

  &:hover{
    background-color: white !important;
  }
}

.share-modal-content {
  text-align: center;
  padding: 20px;

  .share-link-container {
    display: flex;
    gap: 10px;
    margin: 15px 0;

    input {
      flex: 1;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  .btn-primary {
    background: $tt-gold;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .btn-secondary {
    background: $tt-green;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
}

.descriptionWrapper{
  margin: 24px;
}

.boardDescription{
  text-align: start;
}

.explanationHedaer{
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 18px;
}

.explanation{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 42px 42px 0 42px;
  font-weight: 400;
  text-align: center;
}

@media (max-width:583px){
  .custom_close{
    top: 1rem !important;
    left: 1rem !important;
  }
  .quiz .quiz__navigation__button{
    height: 40px !important;
    padding: 10px !important;
  }
}

</style>
