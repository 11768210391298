<template>
  <div class="room-builder-stage">
    <div class="row row-1">
      <div class="stage-area" ref="stageArea">
        <div class="stage-area__instructions" v-show="selectedItems.length === 0">
          CLICK &amp; DRAG<br/> {{ isPreview ? '' : 'YOUR SELECTION HERE'}}
        </div>
      </div>

      <div class="items" v-for="(obj, i) in selectedItems" :key="obj.id">
        <RoomBuilderStageItem
          :ref="`stage-item-${i}`"
          :id="obj.id"
          :name="obj.name"
          :itemPrice="obj.itemPrice"
          :size="obj.size"
          :globalScale="selectedItems.length > 5 ? 1 : 1.25"
          :src="obj.src"
          :x="obj.x"
          :y="obj.y"
          :offsetX="offsetX"
          :offsetY="offsetY"
          :percentX="obj.percentX"
          :stageWidth="stageWidth"
          :rect="obj.rect"
          @ITEM_MOUSEOVER="handleMouseOver"
          @ITEM_MOUSEOUT="handleMouseOut"
          @ITEM_CLICK="handleItemClick"
          @ITEM_CLOSE="handleItemClose"
          @ITEM_INFO="handleItemInfo"
          @ITEM_DRAG_RELEASE="handleItemDragRelease"
        />
      </div>
    </div>

    <div class="row footer" v-if="!hideFooter">
      <div class="items-selected">
        {{ selectedItems.length }} items selected
      </div>
      <button type="button" class="btn checkout-button primary customGreenBTN" @click="handleCheckout">
        ${{ total }} - Continue
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import RoomBuilderStageItem from '@/components/room-builder/room-builder-stage-item.vue'

export default {
  components: {
    RoomBuilderStageItem
  },

  props: {
    offsetY: {
      type: Number,
      default: null
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    shouldSaveItems: {
      type: Boolean,
      default: true
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedItem: null,
      total: 0,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      offsetX: null,
      stageWidth: 0
    }
  },

  computed: {
    ...mapState(['selectedItems']),

    itemsFormatted () {
      const minimal = []
      this.selectedItems.forEach((item, index) => {
        minimal.push({
          id: item.id,
          x: item.x,
          y: item.y,
          percentX: item.percentX
        })
      })

      return minimal
    }
  },

  mounted () {
    this.$el.onmousedown = this.handleMouseDown
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },

  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    ...mapMutations(['addSelectedItem', 'removeSelectedItem']),
    ...mapActions(['updateTargetSelection']),

    handleResize () {
      this.offsetX = this.$el.getBoundingClientRect().left
      this.stageWidth = this.$el.getBoundingClientRect().width
    },

    getPercentX (itemX) {
      return (itemX - this.offsetX) / this.stageWidth
    },

    /**
     * Called from parent components. Used to add an item to the selected area.
     */
    addItem (payload) {
      this.addSelectedItem({
        id: payload.id,
        name: payload.name,
        itemPrice: payload.price,
        size: payload.size,
        src: payload.image,
        x: payload.x,
        y: payload.y + window.scrollY,
        percentX: payload.percentX
      })

      if (this.shouldSaveItems) {
        this.saveItems()
        this.calculateTotal()
      }
      this.emitter.emit('SELECTED_ITEMS', this.selectedItems)
    },

    removeItem (obj) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (obj.id === this.selectedItems[i].id) {
          this.removeSelectedItem(i)
          this.emitter.emit('ITEM_REMOVED', { id: obj.id })
          break
        }
      }

      if (this.shouldSaveItems) {
        this.saveItems()
        this.calculateTotal()
      }
      this.emitter.emit('SELECTED_ITEMS', this.selectedItems)
    },

    /**
     * Saves the selected furniture and positions in the Firestore database
     */
    async saveItems () {
      this.itemsFormatted.map(el => { el.selectionId = this.$route.params.selectionId })
      await this.updateTargetSelection({
        target: this.$route.params.selectionId,
        data: {
          chosen: this.itemsFormatted
        }
      })
    },

    calculateTotal () {
      var t = 0
      for (let i = 0; i < this.selectedItems.length; i++) {
        t += this.selectedItems[i].itemPrice
      }

      this.total = t.toFixed(2)
    },

    withinBounds (obj) {
      const stageRect = this.$el.getBoundingClientRect()
      const rect = obj.getBoundingClientRect()
      const rectWidthCenter = rect.width / 2
      const rectHeightCenter = rect.height / 2

      let isWithinBounds = true

      if (rect.left + rectWidthCenter < stageRect.left) isWithinBounds = false
      if (rect.right - rectWidthCenter > stageRect.right) isWithinBounds = false
      if (rect.top + rectHeightCenter < stageRect.top) isWithinBounds = false
      if (rect.bottom - rectHeightCenter > stageRect.bottom) isWithinBounds = false

      return isWithinBounds
    },

    /**
     * When an element is dragged within the selected area,
     * we'll need to save its updated position.
     */
    updatePosition (e) {
      this.selectedItems.forEach((item, index) => {
        if (item.id === e.id) {
          this.selectedItems[index].x = e.item.getBoundingClientRect().left
          this.selectedItems[index].y = e.item.getBoundingClientRect().top + window.scrollY
          this.selectedItems[index].percentX = this.getPercentX(this.selectedItems[index].x)
        }
      })
    },

    handleMouseDown (e) {
      e = e || window.event
      e.preventDefault()

      if (e.target.classList.contains('stage-area')) {
        if (this.selectedItem) {
          this.selectedItem.deselect()
          this.selectedItem = null
          this.$el.style.overflow = 'hidden'
        }
      }
    },

    async handleCheckout () {
      this.$router.push({ path: `/checkout/${this.$route.params.selectionId}` })
    },

    handleMouseOver (e) {

    },
    handleMouseOut (e) {
      if (this.selectedItem) {
        this.selectedItem.deselect()
      }
    },

    handleItemClick (e) {
      // if (this.selectedItem) {
      //   if (e.item !== this.selectedItem) {
      //     this.selectedItem.deselect()
      //   }
      // }

      this.selectedItem = e.item
      this.$el.style.overflow = 'visible'
    },

    handleItemClose (e) {
      this.removeItem(e)
    },

    handleItemInfo (e) {
      this.$emit('ITEM_INFO', e.id)
    },

    handleItemDragRelease (e) {
      // When the user selects another item, we want to close the details modal
      this.emitter.emit('FURNITURE_DETAIL_CLOSE')
      this.updatePosition(e)
      if (this.shouldSaveItems) {
        this.saveItems()
      }

      if (!this.withinBounds(e.item)) this.removeItem(e)
      this.$el.style.overflow = 'hidden'
    }
  }
}
</script>

<style lang="scss" scoped>
.room-builder-stage {
  position: relative;
  width: 100%;
  // height: 760px;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .row-1 {
    position: relative;
    margin: 0;
    padding: 0;
    height: 660px;

    .stage-area {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: calc(100% - 85px);
      height: calc(100% - 60px);
      margin-top: 20px;
      top: 0;
      left: 40px;
      background: #FFFEFE;
      border: 1.25px dashed #73716F;
      border-radius: 10.2941px;
    }

    .items {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    position: relative;
    margin: 0;
    padding: 0 40px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .items-selected {
      font-family: 'GT America Expanded';
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.04em;
      color: #1D1B19;
    }

    .checkout-button {
      border-radius: 25px;
      width: 257px;
      height: 50px;
      transition: all 0.2s ease-in-out;
    }
  }

  @include breakpoint($m-down) {
    .room-builder-stage {
      height: auto;
      background: red;
    }

    .row-1 {
      display: none;
    }

    .footer {
      margin-top: 10px;
      padding-top: 15px;
      flex-direction: column;
    }
  }
}
</style>
